@media (min-width: 768px) {
  .filter-bar-company-center {
    width: calc(100% - 260px);
  }
  .filter-bar-company-left {
    width: 260px;
    float: left;
  }
}

.company-filter-bar-row .filter-bar-item .filter-item .filter-item-title {
  width: 112px !important;
}

@media (min-width: 768px) {
  .filter-bar-center {
    width: calc(100% - 470px);
  }
  .filter-bar-left {
    width: 265px;
    float: left;
  }
  .filter-bar-right {
    width: 205px;
    float: left;
  }
}
.filter-bar-right {
  display: flex;
}
.filter-bar-right-content {
  width: 100%;
  display: inline-block;
  align-self: flex-end;
}
