.inline-edit-actions .inline-edit-actions-item {
  margin-right: 3px;
  cursor: pointer;
}
.inline-edit-actions .inline-edit-actions-item-disabled {
  margin-right: 3px;
  cursor: not-allowed;
}
.inline-edit-actions {
  text-align: right;
  width: 81px;
}

.inline-edit-headers {
  margin-bottom: var(--cui-spacing-qtr) !important;
  display: flex;
  flex-wrap: wrap;
}

.inline-edit-title,
.inline-edit-actions {
  display: inline-block;
}
.inline-edit-title {
  width: calc(100% - 81px);
}
