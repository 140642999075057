.dropdown {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 10px;
}

.dropdown .dropdown-content {
  opacity: 1;
  visibility: visible;
  height: auto;
  transition: opacity var(--cui-animate-speed);
  list-style: none;
  display: inline-flex;
  flex-direction: column;
  background: var(--cui-dropdown-background-color);
  border: var(--cui-border);
  border-radius: var(--cui-border-radius);
  color: var(--cui-font-color);
  overflow: hidden auto;
  position: absolute;
  pointer-events: auto;
  top: 25px;
  transition: opacity var(--cui-animate-speed);
  text-align: left;
  box-shadow: var(--cui-shadow-outset);
  -webkit-user-select: none;
  user-select: text;
  z-index: var(--cui-dropdown-zindex);
  min-width: 160px;
  max-width: 300px;
  max-height: 300px;
  background-color: #fff;
  left: 0;
  padding: 8px 8px;
  cursor: initial;
}
