/* .error, .error:focus{
  border: 1px solid red !important;
} */

.rs-picker-disabled {
  opacity: 1 !important;
}

.audit-report-table-modal .filter-bar-center-modal {
  width: 100%;
}

.cui .form-group .form-datepicker input {
  padding: 10px;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  border-color: var(--cui-accent-color) !important;
}
.report-schedule-confirm-dialog .modal__dialog {
  width: var(--cui-modal-width-small) !important;
  height: auto !important;
}
.scheduled-date-list-wrap {
  max-height: 385px;
  min-height: 385px;
  overflow: auto;
}
.scheduled-date-item .scheduled-date-label {
  min-width: 118px;
  display: inline-block;
}

.table thead tr th {
  text-align: center !important;
}

.scroll-y {
  max-height: 470px;
  overflow-y: auto;
  min-height: 300px;
}
