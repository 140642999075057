.cui .basic-multi-select input:focus {
  box-shadow: none !important;
}
.cui .basic-multi-select input {
  min-height: 20px !important;
  height: 20px !important;
}

.react-select-error div {
  border-color: var(--cui-theme-danger);
}

.react-select-error:hover div {
  border-color: var(--cui-theme-danger);
}
