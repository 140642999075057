.inline-edit-actions .inline-edit-actions-item {
  margin-right: 3px;
  cursor: pointer;
}
.inline-edit-actions {
  text-align: right;
  margin-bottom: var(--cui-spacing-qtr) !important;
}
.filter-item {
  display: inline-block;
  padding: 0px 5px;
  margin-bottom: 6px;
  vertical-align: top;
}
.filter-item .filter-item-title {
  padding: 0px 5px;
}
.rotate-90 {
  transform: rotate(90deg) !important;
}
.dot-btn {
  padding: 5px 3px;
  border: var(--cui-border);
  border-radius: 2px;
  height: 35px;
  line-height: 22px;
}

.filter-item .form-group__text {
  background-color: #f8f8f8;
  padding-left: 5px;
}
.filter-item .form-group__text input {
  background-color: #fff !important;
}
.filter-item .select__control {
  height: 35px !important;
}
.filter-item .select__value-container {
  padding: 0px 4px;
}
.cui .form-group.filter-check-item.disabled {
  background-color: rgb(238, 238, 238);
}
.cui .form-group .form-datepicker input {
  padding: 10px;
}

.schedule-filter-item {
  display: inline-block;
  padding: 0px 5px;
  margin-bottom: 6px;
  vertical-align: top;
  width: calc(50% - 20px);
}
.schedule-filter-menu {
  width: 40px;
}
.schedule-filter-menu .schedule-filter-menu {
  margin: 0px;
}
.schedule-filter-wrap {
  width: 100%;
}
