.dropdown .dropdown-title {
  display: flex;
}

.dropdown-menu a {
  text-align: center;
  display: block;
  white-space: nowrap;
  margin: 0;
  padding: 10px 10px;
}
.dropdown-menu a .icon {
  margin-left: 5px;
}

.cui .header .dropdown.dropdown-menu .dropdown__menu {
  top: calc(100% + 1px);
  border-top: none;
  min-width: 320px;
  padding: 8px 0px !important;
}
.cui .dropdown_child__menu {
  padding-left: 15px;
}
