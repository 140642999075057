.user-info-wrap .user-info-item {
  width: 100%;
  display: block;
}

.user-info-item.border-bottom {
  border-bottom: 1px solid var(--cui-border-color);
}

.header-menu .dropdown-menu .dropdown-title a.active:after {
  height: 3px;
  position: absolute;
  right: var(--ds-space-050, 4px);
  bottom: 0px;
  left: var(--ds-space-050, 4px);
  background-color: var(--cui-color-sky);
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  content: '';
}

.header-menu .dropdown__menu a.active {
  color: var(--cui-active-color);
  background-color: var(--cui-background-hover);
}
.dropdown_child__menu {
  display: grid;
}
