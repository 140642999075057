.inline-edit-actions .inline-edit-actions-item {
  margin-right: 3px;
  cursor: pointer;
}

.inline-edit-actions {
  text-align: right;
  margin-bottom: var(--cui-spacing-qtr) !important;
}

.filter-item {
  display: inline-block;
  padding: 0px 5px;
  margin-bottom: 6px;
  vertical-align: top;
}

.filter-item .filter-item-title {
  padding: 0px 5px;
}

.rotate-90 {
  transform: rotate(90deg) !important;
}

.dot-btn {
  padding: 5px 3px;
  border: var(--cui-border);
  border-radius: 2px;
}

.filter-item .form-group__text {
  background-color: #f8f8f8;
  padding-left: 5px;
}

.filter-item .form-group__text input {
  background-color: #fff !important;
}

.filter-item .select__control {
  height: 35px !important;
}

.filter-item .select__value-container {
  padding: 0px 4px;
}

.cui .form-group.filter-check-item.disabled {
  background-color: rgb(238, 238, 238);
}

.cui .form-group .datepicker input {
  padding: 10px;
}

.datepicker--error > .rs-picker-toggle.rs-btn.rs-btn-default {
  border-color: var(--cui-theme-danger) !important;
  border-radius: var(--cui-border-radius) !important;
  box-shadow: none;
}

.datepicker--error > .rs-picker-toggle.rs-btn.rs-btn-default:hover {
  border-color: var(--cui-theme-danger) !important;
  border-radius: var(--cui-border-radius) !important;
  box-shadow: none;
}

.cui .form-group .datepicker--error input:focus {
  border-color: var(--cui-theme-danger) !important;
  box-shadow: 0 0 4px var(--cui-focus-thickness)
    rgba(var(--cui-theme-danger-rgb), var(--cui-focus-opacity)) !important;
  opacity: 1 !important;
  outline: transparent var(--cui-focus-thickness) !important;
  outline-offset: 1px !important;
  outline-width: thin !important;
  outline-style: none !important;
}
