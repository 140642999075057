.symbol-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 10px;
}

.symbol-group .symbol {
  position: relative;
  z-index: 0;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.symbol-group.symbol-hover .symbol {
  cursor: pointer;
}

.symbol-group .symbol.symbol-hover,
.symbol-group .symbol .symbol-hover {
  cursor: pointer;
}

.cui .symbol + .symbol {
  margin-bottom: 0px;
  margin-left: 3px;
}

.symbol-content .symbol + .symbol {
  margin-top: 3px;
  margin-left: 0px;
}

.symbol-dropdown {
  opacity: 1;
  visibility: visible;
  height: auto;
  transition: opacity var(--cui-animate-speed);
  list-style: none;
  display: inline-flex;
  flex-direction: column;
  background: var(--cui-dropdown-background-color);
  border: var(--cui-border);
  border-radius: var(--cui-border-radius);
  color: var(--cui-font-color);
  overflow: hidden auto;
  position: absolute;
  pointer-events: auto;
  top: 33px;
  transition: opacity var(--cui-animate-speed);
  text-align: left;
  box-shadow: var(--cui-shadow-outset);
  -webkit-user-select: none;
  user-select: none;
  z-index: var(--cui-dropdown-zindex);
  min-width: 150px;
  max-width: 300px;
  max-height: 300px;
  background-color: #fff;
  left: 0;
}

.symbol-content {
  padding: var(--cui-spacing-half);
}
